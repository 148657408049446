import styled from '@emotion/styled';
import { EmptyClassroom } from '@innovamat/glimmer-assets';
import { Typography } from '@innovamat/glimmer-components';
import { useTranslation } from 'react-i18next';

const ErrorWrapper = styled.div`
  margin-top: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 24px;
`;

const ErrorTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  text-align: center;
  align-items: center;
`;

const ErrorTextSubtitle = styled(Typography.Body2)`
  color: ${({ theme }) =>
    theme.tokens.color.alias.cm.text['text-subtle'].value};
`;

export function NoClassesError(): JSX.Element {
  const { t } = useTranslation();
  return (
    <ErrorWrapper>
      <EmptyClassroom />
      <ErrorTextWrapper>
        <Typography.H4>
          {t('reports.admin.no-classes.error.title')}
        </Typography.H4>
        <ErrorTextSubtitle>
          {t('reports.admin.no-classes.error.description')}
        </ErrorTextSubtitle>
      </ErrorTextWrapper>
    </ErrorWrapper>
  );
}
