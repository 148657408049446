import {
  Agrupation,
  Extension,
  Formation,
  Management,
  PageBreak,
  PartDivider,
  PasoNumerado,
  Solution,
  StepMedia,
  StepType,
  Support,
  TextItem,
  WhatToObserve,
} from '../types/SmartGuide';
import { TableSmartguides } from '../types/Table';
import { mapMedia } from './mapMedia';
import { mapTable } from './mapTable';
import { mapWhatToObserve } from './mapWhatToObserve';

export function mapSteps(
  data: any,
  partNumber: number,
  regionParameters: any
): StepType[] {
  return data.map((item: any) => {
    switch (item.__typename) {
      case 'PasoNumerado':
        return {
          __typename: 'PasoNumerado',
          content: mapSteps(item.content, partNumber, regionParameters),
          evaluable: {
            isEvaluable: item.paso_evaluable,
            tooltipText: item.nombre_del_evaluable,
            linkTo: item.enlace_al_evaluable,
            dimension: +item.dimensin_de_evaluacin,
          },
          stepNumber: item.numero_del_paso,
        } as PasoNumerado;
      case 'Agrupacin':
        return {
          __typename: 'Agrupacin',
          part: partNumber,
          text: `digitalguides.agrupation.${item.agrupacin}`,
        } as Agrupation;
      case 'Text':
        return {
          __typename: 'Text',
          text: item.body,
        } as TextItem;
      case 'Solucin':
        return {
          __typename: 'Solucin',
          media: mapMedia(item.media, item.ancho_de_imagen, regionParameters),
          part: partNumber,
          text: item.descripcin,
          tables: mapTable(item, regionParameters),
        } as Solution;
      case 'Subactividad':
        return {
          __typename: 'Subactividad',
          text: item.texto,
        } as PartDivider;
      case 'QuePodemosObservar':
        return {
          __typename: 'QuePodemosObservar',
          items: mapWhatToObserve(item, regionParameters),
        } as WhatToObserve;
      case 'Apoyo':
        return {
          __typename: 'Apoyo',
          media: mapMedia(item.media, item.ancho_de_imagen, regionParameters),
          text: item.descripcin,
          tables: mapTable(item, regionParameters),
        } as Support;
      case 'Formacin':
        return {
          __typename: 'Formacin',
          media: mapMedia(item.media, item.ancho_de_imagen, regionParameters),
          text: item.descripcin,
          tables: mapTable(item, regionParameters),
        } as Formation;
      case 'Ampliacin':
        return {
          __typename: 'Ampliacin',
          media: mapMedia(item.media, item.ancho_de_imagen, regionParameters),
          text: item.descripcin,
          tables: mapTable(item, regionParameters),
        } as Extension;
      case 'ConsejoDeGestin':
        return {
          __typename: 'ConsejoDeGestin',
          media: mapMedia(item.media, item.ancho_de_imagen, regionParameters),
          text: item.descripcin,
          tables: mapTable(item, regionParameters),
        } as Management;
      case 'ZzImagen':
        return {
          __typename: 'ZzImagen',
          media: mapMedia(item.media, item.ancho_de_imagen, regionParameters),
        } as StepMedia;
      case 'TableSmartguides':
        return {
          __typename: 'TableSmartguides',
          tables: mapTable(item, regionParameters),
        } as TableSmartguides;
      case 'PageBreak':
        return {
          __typename: 'PageBreak',
        } as PageBreak;
      default:
        return null;
    }
  });
}
