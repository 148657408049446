import { useEnvVariables, useUser } from '@innovamat/ga-features';
import { IconType } from '@innovamat/glimmer-icons';
import { getLocalesForPrepr, Region } from '@innovamat/localization';
import { useQuery } from '@tanstack/react-query';
import { GraphQLClient } from 'graphql-request';

type ParentLauncherData = {
  Parentlaunchers: {
    items: ParentLauncher[];
  };
};

export type ParentLauncher = {
  title: string;
  more_info_url: string;
  video: {
    url: string;
    cover?: string;
  };
  shortcuts: {
    icon: IconType;
    title: string;
    description: string;
    link: string;
  }[];
  faqs: {
    question: string;
    answer: string;
  }[];
};

const doc = `
{
	Parentlaunchers(limit: 1) {
		items {
			title
			more_info_url
			video {
				url
        cover
			}
			shortcuts {
				icon
				title
				description
				link
			}
			faqs {
				question
				answer
			}
		}
	}
}
`;

const getParentLauncher = async (
  locales: string[],
  PREPR_GRAPHQL: string
): Promise<ParentLauncherData> => {
  const graphQLClient = new GraphQLClient(PREPR_GRAPHQL);

  return graphQLClient.request(doc, { locales });
};

export function usePreprParentLauncher(): {
  data: ParentLauncher;
  isError: boolean;
  isLoading: boolean;
} {
  const { PREPR_GRAPHQL } = useEnvVariables();
  const { user } = useUser();
  const DEFAULT_LOCALE = ['es-ES'];
  const region = user?.region ? (user.region as Region) : 'ES';
  const locales = getLocalesForPrepr(region, user?.locale!);

  const query = useQuery({
    queryKey: ['ParentLauncher', locales],
    queryFn: async () => {
      return getParentLauncher(locales || DEFAULT_LOCALE, PREPR_GRAPHQL);
    },
    staleTime: Infinity,
  });

  const firstParentLauncher = query.data?.Parentlaunchers
    .items[0] as ParentLauncher;

  return {
    data: firstParentLauncher,
    isError: query.isError,
    isLoading: query.isLoading,
  };
}
