import { MediaType, MediaWidth } from '../types/Media';

function extractId(url: string) {
  const regex = /https:\/\/stream\.mux\.com\/([a-zA-Z0-9]+)/;
  const match = url.match(regex);

  return match ? match[1] : null;
}

const getWidth = (width: string): MediaWidth => {
  if (!width) return 50;
  return +width as MediaWidth;
};

const checkLocalization = (item: any, regionParameters: any): boolean => {
  const localizationFields = [
    'clock',
    'currency',
    'decimalSeparator',
    'metricSystem',
    'numberWriting',
    'shortDivisionSign',
    'temperature',
    'verticalMultiplicationPosition',
    'verticalSubtractionPosition',
    'verticalSumPosition',
  ];

  for (const field of localizationFields) {
    if (item[field] && item[field] !== regionParameters[field]) {
      return false; // Si algún campo no coincide, se excluye
    }
  }
  return true; // Todos los campos coinciden o están vacíos
};

export function mapMedia(
  data: any,
  width: string,
  regionParameters: any
): MediaType[] {
  return data
    ?.filter((item: any) => checkLocalization(item, regionParameters))
    .map((item: any) => {
      const media: MediaType = {
        id: item._id,
        type: item._type,
        width: getWidth(width),
        name: item.name,
        url: item.url,
        files: item.cdn_files?.map((file: any) => file.url),
        playbackId: extractId(item.url),
      };
      return media;
    });
}
