import { Cell, Row, TableContentType } from '../types/Table';
import { mapMedia } from './mapMedia';

function mapRows(
  tableCells: any,
  colums: number,
  regionParameters: any
): Row[] {
  const rows: Row[] = [];
  const cells = tableCells.map((item: any) => mapCell(item, regionParameters));

  for (let i = 0; i < cells.length; i += colums) {
    const row = cells.slice(i, i + colums);
    rows.push(row);
  }

  return rows;
}

function mapCell(item: any, regionParameters: any): Cell {
  return {
    isHeader: item.header_cell,
    media: item.image_cell_content
      ? mapMedia(
          [item.image_cell_content],
          item.ancho_de_imagen,
          regionParameters
        )
      : null,
    text: item.text_cell_content,
  };
}

export function mapTable(
  data: any,
  regionParameters: any
): TableContentType[] | null {
  if (data.smartguidetable.length === 0) {
    return null;
  }

  return data?.smartguidetable?.map((item: any, index: number) => {
    const columnPercentage: number[] =
      item.table_configuration.column_percentage
        .split(',')
        .map((column: any) => +column.trim());
    return {
      title: `${data.table_title}-${index}`,
      columnPercentage,
      rows: mapRows(
        item.table_cells,
        columnPercentage.length,
        regionParameters
      ),
    } as TableContentType;
  });
}
